import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import styles from './ScrollMagicComponent.module.scss'; // Import css modules stylesheet as styles
import { ReactComponent as Beratung } from '../img/beratung.svg';
import { ReactComponent as Operativ } from '../img/operativ.svg';
import { ReactComponent as Plugnplay } from '../img/plugnplay.svg';
import { ReactComponent as Arrow } from '../img/arrow.svg';
import { ReactComponent as Ecke } from '../img/ecke.svg';

const ScrollMagicComponent = () => {
    useEffect(() => {
        // ScrollMagic Controller erstellen
        // const controller = new ScrollMagic.Controller();

        // Eine Szene erstellen
        // new ScrollMagic.Scene({triggerElement: '#step1', duration: 2000, reverse: true}).setPin('#step1', {pushFollowers: true}).addTo(controller);
        // new ScrollMagic.Scene({triggerElement: '#step2', duration: 2000, reverse: true}).setPin('#step2', {pushFollowers: true}).addTo(controller);
        // new ScrollMagic.Scene({offset: 300, reverse: true}).setClassToggle("#claim", styles.top).addTo(controller);
        // new ScrollMagic.Scene({offset: 300, reverse: true}).setClassToggle("#arrow1", styles.invisible).addTo(controller);
        // new ScrollMagic.Scene({offset: 600, reverse: true}).setClassToggle("#firstText", styles.visible).addTo(controller);

        // // new ScrollMagic.Scene({duration: 2000, reverse: true}).setPin('#step2').addTo(controller);
        // new ScrollMagic.Scene({offset: 800, reverse: true}).setClassToggle("#beratung", styles.invisible).addTo(controller);
        // new ScrollMagic.Scene({offset: 1000, reverse: true}).setClassToggle("#operativ", styles.invisible).addTo(controller);
        // new ScrollMagic.Scene({offset: 1200, reverse: true}).setClassToggle("#plugnplay", styles.invisible).addTo(controller);

        // new ScrollMagic.Scene({offset: 2470, duration: 2000, reverse: true}).setPin('#step1').addTo(controller);
        // new ScrollMagic.Scene({offset: 3070, duration: 2000, reverse: true}).setClassToggle("#step1", styles.aaa).addTo(controller);

        // new ScrollMagic.Scene({offset: 2470, reverse: true}).setClassToggle("#ecke", styles.visible).addTo(controller);
        // new ScrollMagic.Scene({offset: 2800, reverse: true}).setClassToggle("#beratung2", styles.visible).addTo(controller);

        // new ScrollMagic.Scene({duration: 4000, reverse: true}).setPin('#step3', {pushFollowers: false}).addTo(controller);
        // new ScrollMagic.Scene({duration: 2800, reverse: true}).setClassToggle("#step3", styles.visible).addTo(controller);
        // new ScrollMagic.Scene({offset: 2800, reverse: true}).setClassToggle("#head_beratung", styles.visible).addTo(controller);
        // new ScrollMagic.Scene({offset: 3000, reverse: true}).setClassToggle("#head_beratung", styles.top).addTo(controller);

        // new ScrollMagic.Scene({offset: 3300, reverse: true}).setClassToggle("#bq1", styles.visible).addTo(controller);
        // new ScrollMagic.Scene({offset: 3600, reverse: true}).setClassToggle("#bq2", styles.visible).addTo(controller);
        // new ScrollMagic.Scene({offset: 3900, reverse: true}).setClassToggle("#bq3", styles.visible).addTo(controller);
        
    
        var controller = new ScrollMagic.Controller();

        // Dauer der Fixierung in Pixeln
        var duration = 2000;

        // Erstes Element
        new ScrollMagic.Scene({
            triggerElement: "#section1",
            triggerHook: 0,
            duration: duration,
            offset: '-140'
        })


        .setPin(`#section1 .${styles.inner}`, { spacerClass: styles.aaa }) // pinSpacing verhindert zusätzlichen Abstand
        // .addIndicators({name: "Section 1"}) // Zum Debuggen aktivieren
        .addTo(controller);

        // Zweites Element
        new ScrollMagic.Scene({
            triggerElement: "#section2",
            triggerHook: 0,
            duration: duration,
            offset: '-140'
        })
        .setPin(`#section2 .${styles.inner}`, { spacerClass: styles.aaa }) // pinSpacing verhindert zusätzlichen Abstand
        // .addIndicators({name: "Section 2"})
        .addTo(controller);

        // Drittes Element
        new ScrollMagic.Scene({
            triggerElement: "#section3",
            triggerHook: 0,
            duration: duration,
            offset: '-140'
        })
        .setPin("#section3 #inner3", { spacerClass: styles.aaa })
        // .addIndicators({name: "Section 3"})
        .addTo(controller);


        // Cleanup bei unmounten
        return () => {
            controller.destroy();
        };
    }, []);


    // Funktion zum Scrollen an eine bestimmte Position
    const scrollToPosition = (position) => {
        window.scrollTo({
            top: position, 
            behavior: 'smooth' // Sorgt für einen sanften Scroll-Effekt
        });
    };

    return (
        <div className={styles.unset}>
        <section id="section1" className={styles.section1}>
            <div id="inner1" className={styles.inner}>
            <h1>Erstes Element</h1>
        </div>
        </section>

    <section id="section2" className={styles.section2}>
        <div id="inner2" className={styles.inner}>
            <h1>Zweites Element</h1>
        </div>
    </section>

    <section id="section3" className={styles.section3}>
        <div id="inner3"  className={styles.inner}>
            <h1>Drittes Element</h1>
        </div>
    </section>
        </div>
    );
};

export default ScrollMagicComponent;
