import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import styles from './Intro.module.scss'; // Import css modules stylesheet as styles
import { ReactComponent as Beratung } from '../img/beratung.svg';
import { ReactComponent as Operativ } from '../img/operativ.svg';
import { ReactComponent as Plugnplay } from '../img/plugnplay.svg';
import scrollToPosition from '../utils';
import Arrow from '../components/Arrow';

const Intro = ({children, duration, id}) => {
    useEffect(() => {
    
        const controller = new ScrollMagic.Controller();

        // Dauer der Fixierung in Pixeln
        // var duration = 2000;

        // Erstes Element
        new ScrollMagic.Scene({
            duration: duration,
            offset: '500'
            
        })
        .setClassToggle("#claim", styles.top)
        .addTo(controller);

        
        new ScrollMagic.Scene({offset: 1000, reverse: true}).setClassToggle("#firstText", styles.visible).addTo(controller);

        new ScrollMagic.Scene({offset: 1500, reverse: true}).setClassToggle("#beratung", styles.invisible).addTo(controller);
        new ScrollMagic.Scene({offset: 1800, reverse: true}).setClassToggle("#operativ", styles.invisible).addTo(controller);
        new ScrollMagic.Scene({offset: 2000, reverse: true}).setClassToggle("#plugnplay", styles.invisible).addTo(controller);

        new ScrollMagic.Scene({offset: 2000, reverse: true}).setClassToggle("#arrow1", styles.invisible).addTo(controller);
        new ScrollMagic.Scene({offset: 4000, reverse: true}).setClassToggle("#arrow2", styles.invisible).addTo(controller);


        // Cleanup bei unmounten
        return () => {
            controller.destroy();
        };
    }, []);

    return (
        <div>
            <h1 id="claim" className={styles.claim}>Change needs Motion.</h1>
            <p id="firstText" className={styles.firstText}>Wir helfen Ihnen in einer digitalen Welt in ­Bewegung zu bleiben.<br/>Sie bestimmen wie viel Unterstützung Sie wollen.</p>
            <button id="beratung" className={`${styles.beratung}`} onClick={() => scrollToPosition(6000)}><Beratung /></button>
            <button id="operativ" className={`${styles.operativ}`} onClick={() => scrollToPosition(9000)}><Operativ /></button>
            <button id="plugnplay" className={`${styles.plugnplay}`} ><Plugnplay /></button>
            <div id="arrow2" class={styles.arrow2}><Arrow position={6000}/></div>
            <div id="arrow1" class={styles.arrow1}><Arrow position={2000}/></div>
        </div>
    );
};

export default Intro;
