import React, { useEffect } from 'react';
import ScrollMagic from 'scrollmagic';
import styles from './Operativ.module.scss'; // Import css modules stylesheet as styles
import { ReactComponent as Ecke } from '../img/ecke.svg';
import { ReactComponent as Icon } from '../img/operativ.svg';

const Operativ = ({children, duration, id}) => {
    useEffect(() => {
    
        const controller = new ScrollMagic.Controller();

        // Dauer der Fixierung in Pixeln
        // var duration = 2000;


        new ScrollMagic.Scene({offset: 7600, reverse: true}).setClassToggle("#head_operativ", styles.visible).addTo(controller);
         new ScrollMagic.Scene({offset: 8000, reverse: true}).setClassToggle("#head_operativ", styles.top).addTo(controller);

        // new ScrollMagic.Scene({offset: 4500, reverse: true}).setClassToggle("#bq1", styles.visible).addTo(controller);
        // new ScrollMagic.Scene({offset: 5000, reverse: true}).setClassToggle("#bq2", styles.visible).addTo(controller);
        // new ScrollMagic.Scene({offset: 5500, reverse: true}).setClassToggle("#bq3", styles.visible).addTo(controller);



        // Cleanup bei unmounten
        return () => {
            controller.destroy();
        };
    }, []);

    return (
        <div>
            <h1 id="head_operativ" className={styles.head_operativ}>
                    Operative Partnerschaft
            </h1>
            <div id="bq1" className={styles.question + ' ' + styles.bq1}>Wie passt eine automatische Fahrgastzählanlageb<br/>
            in meinen Betrieb und wie viele brauche ich davon?</div>
            <div id="bq2" className={styles.question + ' ' + styles.bq2}>Schon von (((etiCORE gehört?</div>
            <div id="bq3" className={styles.question + ' ' + styles.bq3}>Lust auf Mobilitätswende,<br/>aber noch keine Strategie?</div>
        </div>
    );
};

export default Operativ;
